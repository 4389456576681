// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require('src/scss/app.scss');

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap/dist/js/bootstrap");
require('jquery');

require('src/stack/easypiechart.min');
require('src/stack/granim.min');
require('src/stack/smooth-scroll.min');
require('src/stack/scripts');

require("chartkick")
require("chart.js")
global.moment = require('moment');
require('src/tempusdominus-bootstrap-4.min');
require('src/will_paginate_infinite');
//= require tinymce

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
